import type {
    BusinessAmenity,
    BusinessCategory,
    BusinessCategoryTree,
    BusinessCouponType,
    BusinessQuota,
    BusinessReportType,
    BusinessSubscriptionPlan
} from "~/types";

export const useBusinessesResourceDataService = () => {
    const nuxtApp = useNuxtApp();

const fetchBusinessReportTypes = async () => {
    const { data, error } = await useAsyncData('business-report-types',
        () => nuxtApp.$getBusinessReportTypes()
    );

    if (error.value != null) {
        setError(error.value.data as ApiError);
        return null;
    } else {
        return data.value.data as BusinessReportType[];
    }
}

const fetchBusinessesQuota = async (filter?:string|null) => {
    const { data, error } = await useAsyncData(`${filter??'all'}-businesses-quota`,
        () => nuxtApp.$getBusinessQuotas()
    );

    if (error.value != null) {
        setError(error.value.data as ApiError);
        return null;
    } else {
        return data.value.data as BusinessQuota;
    }
}

const fetchBusinessCategories = async () => {
    const { data, error } = await useAsyncData('business-categories',
        () => nuxtApp.$getBusinessCategories()
    );

    if (error.value != null) {
        setError(error.value.data as ApiError);
        return null;
    } else {
        return data.value.data as BusinessCategory[];
    }
}

    const fetchBusinessMajorCategoriesWithSubCategories = async () => {
        const { data, error } = await useAsyncData('business-major-categories-with-sub-categories',
            () => nuxtApp.$getBusinessMajorCategoryWithSubCategories()
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value.data as BusinessCategoryTree[];
        }
    }

const fetchBusinessCouponTypes = async () => {
    const { data, error } = await useAsyncData('business-coupon-types',
        () => nuxtApp.$getBusinessCouponTypes()
    );

    if (error.value != null) {
        setError(error.value.data as ApiError);
        return null;
    } else {
        return data.value.data as BusinessCouponType[];
    }
}

const fetchBusinessAmenities = async () => {
    const { data, error } = await useAsyncData('business-amenities',
        () => nuxtApp.$getBusinessAmenities()
    );
    
    if (error.value != null) {
        setError(error.value.data as ApiError);
        return null;
    } else {
        return data.value.data as BusinessAmenity[];
    }
}

const fetchBusinessSubscriptionPlans = async () => {
    const { data, error } = await useAsyncData('business-subscription-plans',
        () => nuxtApp.$getBusinessSubscriptionPlans()
    );

    if (error.value != null) {
        setError(error.value.data as ApiError);
        return null;
    } else {
        return data.value.data as BusinessSubscriptionPlan[];
    }
}

function setError(error: ApiError) {
    useAlertSetter().setErrorAlert(error);
}

return {
    fetchBusinessReportTypes,
    fetchBusinessesQuota,
    fetchBusinessCategories,
    fetchBusinessCouponTypes,
    fetchBusinessAmenities,
    fetchBusinessSubscriptionPlans,
    fetchBusinessMajorCategoriesWithSubCategories,
}

}