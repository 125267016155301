export const useBusinessesResourceDataManager = () => {
    const route = useRoute();
    const businessesStore = useBusinesses();
    const businessesResourceDataService = useBusinessesResourceDataService()

    const getBusinessCategories = async () => {
        const storedBusinessCategories = computed(() => {
            return businessesStore.business_categories;
        })

        if (storedBusinessCategories.value == null || storedBusinessCategories.value.length == 0 || !businessesStore.isBusinessCategoriesValid()) {
            const businessCategories = await businessesResourceDataService.fetchBusinessCategories();
            if(businessCategories)
            businessesStore.setBusinessCategories(businessCategories);
        }

        return storedBusinessCategories;
    }

    const getBusinessSubscriptionPlans = async () => {
        const storedBusinessSubscriptionPlans = computed(() => {
            return businessesStore.business_subscription_plans;
        })

        if (storedBusinessSubscriptionPlans.value == null || storedBusinessSubscriptionPlans.value.length == 0 || !businessesStore.isBusinessSubscriptionPlansValid()) {
            const businessSubscriptionPlans = await businessesResourceDataService.fetchBusinessSubscriptionPlans();
            if(businessSubscriptionPlans)
            businessesStore.setBusinessSubscriptionPlans(businessSubscriptionPlans);
        }

        return storedBusinessSubscriptionPlans;
    }

    const getBusinessAmenities = async () => {
        const storedBusinessAmenities = computed(() => {
            return businessesStore.business_amenities;
        })

        if (storedBusinessAmenities.value == null || storedBusinessAmenities.value.amenities.length == 0 || !businessesStore.isBusinessAmenitiesValid()) {
            const businessAmenities = await businessesResourceDataService.fetchBusinessAmenities();
            if(businessAmenities)
            businessesStore.setBusinessAmenities(businessAmenities);
        }

        return storedBusinessAmenities;
    }

    const getBusinessCouponTypes = async () => {
        const storedBusinessCouponTypes = computed(() => {
            return businessesStore.business_coupon_types;
        })

        if (storedBusinessCouponTypes.value == null || storedBusinessCouponTypes.value.coupon_types.length == 0 || !businessesStore.isBusinessCouponTypesValid()) {
            const businessCouponTypes = await businessesResourceDataService.fetchBusinessCouponTypes();
            if(businessCouponTypes)
            businessesStore.setBusinessCouponTypes(businessCouponTypes);
        }

        return storedBusinessCouponTypes.value.coupon_types;
    }

     const getBusinessesQuotaByFilter = async (filter?:string|null) => {
        const storedBusinessesQuota = computed(() => {
            return businessesStore.businesses_quota;
        })
        if (storedBusinessesQuota.value == null || !businessesStore.isBusinessesQuotaValid()) {
           const businessesAdQuota = await businessesResourceDataService.fetchBusinessesQuota();
           if(businessesAdQuota) 
           businessesStore.setBusinessesQuota(businessesAdQuota);
        }

        return storedBusinessesQuota.value;
    }

    const getBusinessesCategoriesTree = async (filter?:string|null) => {
        const storedBusinessesCategoriesTree = computed(() => {
            return businessesStore.businesses_categories_tree;
        })
        if (storedBusinessesCategoriesTree.value == null || !businessesStore.isBusinessesCategoriesTreeValid()) {
            const businessesCategoriesTree = await businessesResourceDataService.fetchBusinessMajorCategoriesWithSubCategories();
            if(businessesCategoriesTree)
                businessesStore.setBusinessesCategoriesTree(businessesCategoriesTree);
        }

        return storedBusinessesCategoriesTree.value;
    }

    return {
        getBusinessCategories,
        getBusinessSubscriptionPlans,
        getBusinessAmenities,
        getBusinessesQuotaByFilter,
        getBusinessCouponTypes,
        getBusinessesCategoriesTree,
    }


}